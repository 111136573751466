@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
:root{
  --btcOrange: #FF9900;
}
html {
  scroll-behavior:smooth;
}
body{
    font-family: 'Ubuntu', sans-serif;
    position: relative;
    background: url(./images/background.png) ;
    background-size: cover;
    background-repeat: repeat-y;
    background-position: center;
    height: 3840px;
}
body::-webkit-scrollbar {
  width: .2rem;  
}
 
body::-webkit-scrollbar-track {
  background: black;  
}
 
body::-webkit-scrollbar-thumb {
  background: var(--btcOrange)
}

h2{
  text-align: center;
  color: lightgrey;
  font-family: 'Ubuntu', sans-serif;
  font-size: 4rem !important;
  font-weight: bold !important;
  text-decoration: underline;
}



/************NAVBAR - use rem instead of px for bootstrap*************/
nav {
  z-index: 5; /*navbar on top of particles*/
  
}

.logo{
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
}
.sticky-nav {
  position: fixed !important;
  width:100%;
  top: 0;
}
.navbar-toggler{
  z-index: 3;
}

/************MENU LINKS*************/
nav a.nav-link {
  font-weight: 500;
  letter-spacing: 0.1rem;
  cursor: pointer;
}
nav a.nav-link:hover{
  transition: .4s ease-in-out;
}

/*nav list text in upper case*/
nav ul li {
  text-transform: uppercase;
  margin: 2rem;
}

.navbar-nav > li > a {
  margin-right: .75rem;
  margin-left: .75rem;
}

.navbar-brand{
  max-width: 17.25rem;
  padding: 0 !important;
  height: 5rem !important;
}

.navbar-nav > li > a.active, .navbar-nav > li > a.active:focus {
  padding: 0;
  border-bottom: 0.18rem solid gray
}
.navbar-nav > li  > a:hover {
  padding: 0;
  border-bottom: 0.18rem solid gray

}

/************HEADER*************/
.component-wraper {
  position: relative;  
  height: 90vh; 
}

.about-words{
  justify-content: center;
  
}
.main-info{
  display: flex;
  /*Put in absolute center of page*/
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;  

  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
}
.main-info h1 {
  color: lightgray;
  text-transform: uppercase;
}
.main-info p {
  color: lightgray;
  font-size: 1.3rem;
}

.typed-text{
  color: lightgray;
  font-size: 3rem;
}
.contact {
  border: .0625rem solid var(--btcOrange);
  text-transform: uppercase;
  border-radius: 0;
  padding: .625rem .75rem .75rem .75rem;
  color: lightgray;
  margin: 2rem;
  z-index: 2; /*on top of particles*/  
}
 .contact:hover{
   background-color: var(--btcOrange);
   color: black;
   font-weight: bolder;
   transition: .2s ease-in-out;
 }

/******************PARTICLES********************/
.tsparticles-canvas-el{
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media(max-width: 768px){ /*adjust for small screens (moble)*/
  
  .particles-canvas{
    display: none;
  }
  
  .main-info{
    font-size: 1.6rem !important;
  }
  .typed-text{
    font-size: 1.4rem !important;
  }
  .navbar-brand{
    width: 7rem !important;
  }
  .dappImage{
    max-width: 3rem !important;
  }  
  .about-words{
    font-size: 1rem !important;
    width: 100% !important;
  }
  .about > .links{
    font-size: 1rem !important;
    width:80% !important;
  }
  .about-header{
    font-size: 1.5rem !important;
  }
  .blockchain-words{
    font-size: 1rem !important;
    width: 99% !important;
  }
  .bimage{
    width: 18rem !important;
  }
  .projects > .links{
    font-size: 1rem !important;
    width: 99% !important; 
  }
  .links > h2{
    font-size: 2.5rem !important;
    padding: .5rem !important;
  }   
}
/*Shrink table text when screen is small*/
@media(max-width: 1200px){
  .table-shrink{
    font-size: .6rem;
  }
}

/******************ABOUT********************/
.about{  
  margin: auto;

  color: lightgray;
  text-align: center;
  max-width: 75%;
  font-family: 'Ubuntu', sans-serif;
  font-weight: normal;
  font-size: 2rem;

  position: relative;
  z-index: 2;
}
.about-words{
  WIDTH: 75%;
  margin: 0 auto;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.5rem;
  color: darkgray;
  font-weight: lighter;
  border: .15rem solid var(--btcOrange);
  padding: 2rem;
  background-color: black;
  text-align: center;

  position: relative;
  z-index: 2;
}
.about > .links {
  WIDTH: 55%;
  margin: 0 auto;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.5rem;
  color: darkgray;
  font-weight: lighter;
  border: .15rem solid var(--btcOrange);
  padding: 2rem;
  background-color: black;
  text-align: center;

  position: relative;
  z-index: 2;
}
.about-header {
  color: lightgray;
  font-weight: normal;
  text-decoration: underline;
}



/******************GLOBAL TEXT LINKS********************/

.text-link{
  color: lightgray;
  text-decoration: underline;
}
.text-link:hover{  
   transition: .5s ease-in-out;
   color: black;
   font-weight: bolder;
   background-color: var(--btcOrange);
}
.links {
  margin: auto;
  width: 30%;
  border: .15rem solid var(--btcOrange);
  padding: 2rem;
  background-color: black;
  position: relative;
  z-index: 2;
  font-weight: ligher;
}


.links > .box > .resumeButton {
  display: flex;
  justify-content: center;
}



/******************BLOCKCHAIN********************/

.blockchain-words{
  WIDTH: 75%;
  margin: 0 auto;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.5rem;
  color: darkgray;
  font-weight: lighter;
  border: .15rem solid var(--btcOrange);
  padding: 2rem;
  background-color: black;
  text-align: center;

  position: relative;
  z-index: 2;
  
}

.blockchain-words-paragraph-right > .bimage{
  float: left;
}
.blockchain-words-paragraph-left > .bimage{
  float: right;
}

.bimage{
  border-radius:50%;
  max-width: 100%;
  height:auto;
  width: 25rem;
  padding: 1rem;
}


/********************************DAPP IMAGES******************************************/
.column {
  float: left;
  width: 20%;
  padding: 5px;  
}
/* Clear floats after image containers */
.row::after {
  content: "";
  clear: both;
  display: table;
  
}
.dappImage{
  max-width: 5rem;
}


/******************PROJECTS********************/
.projects{
  color: darkgray; 
  font-family: 'Ubuntu', sans-serif;
  position: relative;
  font-weight: ligher;
  z-index: 2;
  position:relative;
}
.projects > .links {
  width: 75%;
  text-align: center;
  font-size: 1.5rem;
  
}
.projects > .links >  h2 {
  color: lightgray;
  max-width:75%;
  margin: auto;
  padding-bottom: 2rem;

}






